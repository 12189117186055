<template>
  <CmsLayout class="package-page" :class="theme">
    <main class="container-1440">
      <LoaderSpinner v-if="isLoading" />

      <template v-else>
        <BreadCrumbs class="with-side-padding mb-16" :with-current-level="true" :crumbs="crumbs" />

        <div class="with-side-padding">
          <div class="banner dark" :style="{ background }">
            <BadgeSequoia v-if="badge" :text="badge" type="brand" class="mb-4" />
            <div class="name mb-4" v-html="title" />
            <div v-if="price" class="mb-4">
              <span class="price-amount" v-text="price" />&nbsp;
              <span class="price-period" v-text="priceUnit" />
            </div>
            <div v-if="shortDescription" class="short-description" v-text="shortDescription" />
          </div>
        </div>

        <div class="with-side-padding description-block">
          <TextWithTruncation
            class="full-description"
            :source-text="fullDescription"
            number-of-lines="3"
          />
          <ButtonDefault
            v-if="offer.preview && Number(offer.preview.price)"
            type="button"
            class="connect"
            size="large"
            :disabled="isConnectButtonDisabled"
            @click="onClick"
          >
            <span v-html="callToAction" />
          </ButtonDefault>
        </div>

        <div
          v-if="availableChannels.length || adultChannels.length"
          class="available-channels with-side-padding"
        >
          <div class="title mb-24" v-html="getTranslation('available_channels')" />

          <template v-if="package.id !== ADULT_OFFER_ID">
            <div
              v-for="genre in Object.keys(channelsGroupedByGenre)"
              :key="genre"
              class="available-channels"
            >
              <div class="genre-name">
                <IconSVG
                  :svg="IconAir"
                  :svg-path="getGenreIconPath(genres.find((g) => g.id === genre))"
                  size="48"
                />
                <div class="genre-name-info">
                  <div
                    class="genre-title"
                    v-text="shouldUseTvApiV2 ? getTranslation(`genre_${genre}`) : genre"
                  />
                  <div class="genre-channels-count">
                    <span v-html="getTranslation('sub_channels')" />:&thinsp;
                    <span
                      v-text="channelsGroupedByGenre[genre] && channelsGroupedByGenre[genre].length"
                    />
                  </div>
                </div>
              </div>

              <div class="genre-channels-list row">
                <TilePosterChannel
                  v-for="channel in channelsGroupedByGenre[genre]"
                  :key="channel.id"
                  class="col col-desktop-2 col-tablet-2 col-mobile-2"
                  :channel-id="channel.id"
                  :channel-name="channel[shouldUseTvApiV2 ? 'title' : 'name']"
                  :channel-image="getChannelUrl(channel)"
                  :show-favorites="false"
                  :open-watch="true"
                />
              </div>
            </div>
          </template>

          <div v-if="package.id === ADULT_OFFER_ID" class="available-channels">
            <div class="genre-channels-list row">
              <TilePosterChannel
                v-for="channel in adultChannels"
                :key="channel.id"
                class="col col-desktop-2 col-tablet-2 col-mobile-2"
                :channel-name="channel.title"
                :channel-image="{ src: channel.logo, height: 200, width: 356 }"
                :show-favorites="false"
              />
            </div>
          </div>
        </div>

        <div class="available-content with-side-padding">
          <div class="title mb-24" v-html="getTranslation('go_to_view')" />
          <div class="row">
            <a
              v-for="source in availableContent"
              :key="source.url"
              class="source col col-desktop-3 col-desktop-s-4 col-tablet-4 col-mobile-2"
              href=""
              @click.prevent="onContentClick(source.url)"
            >
              <div class="top">
                <BadgeSource :src="source.src" :icon="source.icon" :width="48" :height="32" />
                <IconSVG class="arrow" :svg="IconArrowRight" :size="24" />
              </div>
              <div class="spacer" />
              <span v-text="source.title" />
            </a>
          </div>
        </div>
      </template>
    </main>

    <ModalSequoia v-if="isModalOpen && !showWelcomeScreen" type="fixed" size="small">
      <div class="with-side-and-bottom-padding">
        <div
          class="modal-description"
          v-html="getTranslation('are_you_sure').replace('%title%', title)"
        />
        <div class="buttons-wrapper fluid">
          <ButtonDefault @click="createSub" v-html="getTranslation('continue')" />
          <ButtonDefault
            type="button"
            view="secondary"
            @click="hideModal"
            v-html="getTranslation('cancel')"
          />
        </div>
      </div>
    </ModalSequoia>
  </CmsLayout>
</template>

<script lang="ts">
import { SequoiaPage } from 'src/mixins';
import logger from 'src/utils/logger';
import Component from 'vue-class-component';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import BreadCrumbs from 'src/components/ui/BreadCrumbs.vue';
import { actions } from 'src/store/actions';
import * as api from '../../api';
import { selectors } from 'src/store/selectors';
import { OfferInfo, ShowcaseChannel } from 'src/models/ts/offer';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import TextWithTruncation from 'src/components/ui/TextWithTruncation.vue';
import BadgeSource from 'src/components/ui/BadgeSource.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconArrowRight from 'src/svg/arrow-right.svg';
import IconTV from 'src/svg/tv.svg';
import IconArchive from 'src/svg/archive.svg';
import IconAir from 'src/svg/airplay.svg';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getChannelLogo } from 'src/utils/channel';
import TilePosterChannel from 'src/components/ui/tiles/TilePosterChannel.vue';
import { ADULT_OFFER_ID, ADULT_CHANNELS, SUBSCRIPTION_METHOD_NAMES } from 'src/constants';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import { TGenre } from 'src/store/tv-channels/types';

const log = logger('package-page');

@Component({
  components: {
    LoaderSpinner,
    CmsLayout,
    BreadCrumbs,
    ButtonDefault,
    TextWithTruncation,
    BadgeSource,
    IconSVG,
    TilePosterChannel,
    ModalSequoia,
    BadgeSequoia,
  },
})
export default class PackagePage extends SequoiaPage {
  IconArrowRight = IconArrowRight;
  IconArchive = IconArchive;
  IconTV = IconTV;
  IconAir = IconAir;

  ADULT_OFFER_ID = ADULT_OFFER_ID;

  channels: ShowcaseChannel[] = [];
  offer: OfferInfo = { preview: undefined, channels: [] };

  isLoading = true;

  get crumbs() {
    return [
      { name: this.getTranslation('menu_homescreen'), link: '/' },
      { name: this.getTranslation('packages_title'), link: '/packages' },
      { name: this.title, link: `/packages/${this.$route?.params?.slug}` },
    ];
  }

  get badge() {
    return this.getByLanguage(this.package?.badge);
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get slug() {
    return this.$route.params.slug;
  }

  get packagesItems() {
    return selectors.packages.packagesSelector(this.$store).items || [];
  }

  get package() {
    return this.packagesItems.find((item) => item.slug === this.$route.params.slug);
  }

  get background() {
    return `url(${this.getByLanguage(this.package?.image)?.url}) center / cover no-repeat`;
  }

  get price() {
    return Number(this.offer?.preview?.price);
  }
  get title() {
    return this.getByLanguage(this.package?.title) || '';
  }

  get priceUnit() {
    return this.getByLanguage(this.package?.priceUnit);
  }

  get shortDescription() {
    return this.getByLanguage(this.package?.shortDescription)?.replace(
      '%channelsCount%',
      `${this.channels?.length}`
    );
  }

  get fullDescription() {
    return this.getByLanguage(this.package?.fullDescription) || '';
  }

  get callToAction() {
    return this.existingSubscription
      ? this.getTranslation('already_connected')
      : `${this.getTranslation('connect_for')} ${this.offer.preview?.price || 0} ${
          this.priceUnit || ''
        }`;
  }

  get adultChannels() {
    return this.package?.id === ADULT_OFFER_ID
      ? this.channels
          .map(({ channelId }) => ({ ...ADULT_CHANNELS[channelId], id: channelId }))
          .filter(({ title }) => title)
      : [];
  }

  get availableChannels() {
    return this.channels.map(({ channelId }) => {
      const channelEnhanced = this.getChannelById(channelId);
      const genres = this.shouldUseTvApiV2
        ? channelEnhanced?.relevantGenres?.map((genre) => genre.genreId)
        : channelEnhanced?.info?.metaInfo?.genres?.map((genre) => genre);

      return {
        ...channelEnhanced,
        genres,
      };
    });
  }

  get shouldUseTvApiV2() {
    return this.$store.siteConfig?.tv?.shouldUseTvApiV2;
  }

  get genres() {
    return selectors.tvChannels.genresSelector(this.$store);
  }

  get channelsGroupedByGenre() {
    return this.genres.reduce((acc: Record<string, TChannelEnhanced[]>, genre) => {
      const filteredChannels = this.availableChannels.filter((channel) => {
        return channel?.genres?.includes(genre.id) || channel?.genres?.includes(genre.title);
      });

      if (filteredChannels.length > 0) {
        if (!this.shouldUseTvApiV2) {
          genre.id = genre.title;
        }
        acc[genre.id] = filteredChannels;
      }
      return acc;
    }, {});
  }

  get availableContent() {
    let content: { title: string; url: string; src?: string; icon?: Record<string, unknown> }[] =
      [];

    if (this.channels.length) {
      content = [
        { title: this.getTranslation('tv'), url: '/channels/now', icon: this.IconTV },
        { title: this.getTranslation('best_on_tv'), url: '/archive', icon: this.IconArchive },
      ];
    }

    if (this.package?.vod) {
      content = [
        ...content,
        ...this.package.vod
          .replace(/\s/g, '')
          .split(',')
          .map((source: string) => ({
            title: this.getTranslation(source),
            url: `/vod/${source}`,
            src: actions.vod.getSourceLogoUrl(this.$store, source),
          })),
      ];
    }

    return content;
  }

  get allAvailableSubscriptions() {
    return selectors.QS.allAvailableSubscriptionsSelector(this.$store);
  }

  get existingSubscriptions() {
    return selectors.QS.existingSubscriptionsSelector(this.$store);
  }

  get existingSubscription() {
    return !!this.existingSubscriptions?.find((sub) => sub.offerId === this.package?.id);
  }

  get isConnectButtonDisabled() {
    return !this.isAnonymous && (this.existingSubscription || !this.subscription);
  }

  get subscription() {
    return this.allAvailableSubscriptions?.find((sub) => sub.offerId === this.package?.id);
  }

  created() {
    actions.seo.setMetaTitle(this.$store, this.title);
  }

  async mounted() {
    const packageId = this.package?.id;

    try {
      await actions.tvChannels.loadTvData(this.$store);
      await actions.QS.loadAllAvailableSubscriptionOptions(this.$store);
    } catch (e) {}

    if (packageId) {
      try {
        this.offer = await api.offers.getOffer(packageId);
        this.channels = (await api.offers.getOfferChannels(packageId)).channels || [];
      } catch (e) {
        log.error(e);
      }
    }
    this.isLoading = false;

    actions.common.showFooter(this.$store);
  }

  showModal() {
    actions.common.showModal(this.$store);
  }

  hideModal() {
    actions.common.hideModal(this.$store);
  }

  getChannelUrl(channel: TChannelEnhanced) {
    const height = 200;
    const url = getChannelLogo(channel);
    if (!url) return { src: '' };

    return {
      src: url,
      width: Math.round((height * 16) / 9),
      height: height,
    };
  }

  getGenreIconPath(genre?: TGenre) {
    return genre ? selectors.tvChannels.genreIconSelector(this.$store, genre) : '';
  }

  getChannelById(id: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, id);
  }

  onClick() {
    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    this.showModal();
  }

  async createSub() {
    if (this.subscription) {
      let createSubscription = actions.QS.createSubscriptionUpsale;
      const subMethodName = this.subscription?.subscriptionMethods[0].name;
      const data: Record<string, string> = {
        Id: this.subscription.offerId,
      };

      if (
        subMethodName === SUBSCRIPTION_METHOD_NAMES.UPSALE_V2 ||
        subMethodName === SUBSCRIPTION_METHOD_NAMES.UPSALE_V21
      ) {
        createSubscription = await actions.QS.createSubscriptionUpsaleV2;
        data.CreateSubscriptionToken = this.subscription.createSubscriptionToken;
      } else if (subMethodName === SUBSCRIPTION_METHOD_NAMES.AUTHENTICATED) {
        createSubscription = actions.QS.createSubscription;
      }

      try {
        await createSubscription(this.$store, {
          data,
        });
        await this.$router.replace('/acquiring-status?subscribed=true');
      } catch (err) {
        log.error(err);
        await this.$router.replace(
          `/acquiring-status?subscribed=false&source_url=${encodeURIComponent(location.pathname)}`
        );
      }
    }
  }

  setShowNotificationAuthAndReg() {
    actions.common.showNotificationAuthAndReg(this.$store);
  }

  onContentClick(url: string) {
    this.$router.push(url);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.package-page {
  .container-1440 {
    margin-top: 64px;

    @include tablet {
      margin-top: 48px;
    }

    @include mobile {
      margin-top: 32px;
    }
  }

  .banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 340px;
    padding: 56px 48px 48px;
    overflow: hidden;
    filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.3));
    border-radius: 16px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: var(--z-rock-bottom);
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--c-overlay-lvl-60);
    }

    @include desktop-s {
      padding: 48px;
    }

    @include tablet {
      height: 240px;
      padding: 24px;
    }

    @include mobile {
      height: 184px;
      padding: 16px;
    }

    .name {
      @include heading1;

      @include desktop-s {
        @include heading2;
      }

      @include tablet {
        @include heading3;
      }

      @include mobile {
        @include heading4;
      }
    }

    .price-amount {
      @include heading3;

      @include desktop-s {
        @include heading4;
      }

      @include tablet {
        @include heading4;
      }

      @include mobile {
        @include heading5;
      }
    }

    .price-period {
      @extend %body2;
    }

    .short-description {
      @extend %body2;

      color: var(--c-dark-font-secondary);
    }
  }

  .description-block {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 32px;

    @include desktop-m-plus {
      margin-top: 48px;
    }

    .full-description {
      @extend %body1;

      width: 50%;

      @include desktop-s {
        width: 60%;
      }

      @include mobile-and-tablet {
        width: 100%;
      }
    }

    .connect {
      @include mobile-and-tablet {
        width: 100%;
        margin-top: 32px;
      }
    }
  }

  .title {
    @include heading3;

    margin-top: 32px;

    @include desktop-m-plus {
      margin-top: 48px;
    }
  }

  .source {
    display: flex;
    flex-direction: column;
    height: 120px;
    padding: 16px;
    margin-bottom: 24px;
    border-radius: 12px;

    @include mobile-and-tablet {
      margin-bottom: 16px;
    }

    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .spacer {
      flex: 1;
    }

    .badge-source {
      width: 48px;
      height: 32px;
    }
  }

  .available-channels {
    .genre-name {
      display: flex;
      margin-bottom: 24px;

      @include mobile-and-tablet {
        margin-bottom: 16px;
      }

      &::v-deep .icon {
        margin-right: 22px;
        color: var(--c-dark-brand);
      }

      .genre-name-info {
        display: flex;
        flex-direction: column;

        .genre-title {
          @include heading5;

          margin: 0;
        }

        .genre-channels-count {
          @extend %body2;
        }
      }
    }

    .genre-channels-list {
      .col {
        display: inline-block;
        margin-bottom: 24px;
        cursor: pointer;

        @include mobile-and-tablet {
          margin-bottom: 16px;
        }
      }

      .genre-channels-name {
        @extend %body2;
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .genre-channels-list {
      .col {
        color: var(--c-light-font-primary);
      }
    }

    .genre-channels-count {
      color: var(--c-light-font-tertiary);
    }

    .source {
      color: var(--c-light-font-primary);
      background-color: var(--alpha-light-3);

      @include devices-with-hover {
        &:hover {
          background-color: var(--alpha-light-4);
        }

        &:active {
          background-color: var(--alpha-light-5);
        }
      }

      .badge-source {
        background-color: var(--c-light-100);

        &::v-deep .icon {
          color: var(--c-light-brand);
        }
      }
    }
  }

  &.dark {
    .genre-channels-list {
      .col {
        color: var(--c-dark-font-primary);
      }
    }

    .genre-channels-count {
      color: var(--c-dark-font-tertiary);
    }

    .source {
      color: var(--c-dark-font-primary);
      background-color: var(--alpha-dark-3);

      @include devices-with-hover {
        &:hover {
          background-color: var(--alpha-dark-4);
        }

        &:active {
          background-color: var(--alpha-dark-5);
        }
      }

      .badge-source {
        background-color: var(--c-dark-100);

        &::v-deep .icon {
          color: var(--c-dark-brand);
        }
      }
    }
  }
}
</style>
